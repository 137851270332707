export const handleFocus = async (webcamRef, setDeviceId, setFacingMode) => {
  const videoTrack = webcamRef.current?.stream?.getVideoTracks()[0]
  if (videoTrack && videoTrack.getCapabilities) {
    setDeviceId(videoTrack.getSettings().deviceId)

    const capabilities = videoTrack.getCapabilities()
    if (capabilities.focusMode) {
      await videoTrack.applyContrainst({
        advanced: [{ focusMode: 'continuous' }],
      })
    }
  } else {
    setFacingMode && setFacingMode(undefined)
  }
}
