import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useFormikContext } from 'formik'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { isMediaWidthMoreThan } from '../../../../utils/lib'
import Template from '../../../../components/Template'
import HeaderCustom from '../../../../components/HeaderCustom'
import Breadcrumb from '../../../../components/Breadcrumb'

import * as Styled from './Styled'
import { listBreadcrumbs, listType } from '../model'
import { handleUpload } from '../events'

const Index = () => {
  const { id: uuid } = useParams()
  const user = JSON.parse(localStorage.getItem('user'))
  const userType = _.get(user, 'userType', '')

  // const history = useHistory()
  const dispatch = useDispatch()
  const { information, type } = useSelector(
    (state) => state.manageProspect,
    shallowEqual
  )
  const url = _.isEmpty(_.get(information, 'image', ''))
    ? '/icon/img_empty.png'
    : `${window.__env__.REACT_APP_FILE_URL}${_.get(information, 'image', '')}`

  const status = _.get(information, 'consent', 'WAITING')
  // const checkStatus = _.get(information, 'status', 'INACTIVE')
  const consentText =
    status === 'CONSENT' ? 'ยินยอม' : status === '-' ? '-' : 'รอการยินยอม'
  const isNotSm = isMediaWidthMoreThan('sm')

  return (
    <HeaderCustom>
      <Template>
        <Styled.BoxBreadcrumb>
          <Breadcrumb
            list={listBreadcrumbs(
              uuid,
              type,
              _.get(information, 'fullName', ''),
              userType
            )}
            iconColor="text.white"
          />
        </Styled.BoxBreadcrumb>

        <Styled.BoxImage>
          <Styled.ContentText>
            <Styled.ImageProfile src={url} />
            {type !== listType.view && (
              <label htmlFor="btn-upload-profile">
                <Styled.InputUpload
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  id="btn-upload-profile"
                  onChange={(files) => dispatch(handleUpload(files))}
                />
                <Styled.IconChangeImage htmlFor="btn-upload-profile">
                  <Styled.IconEdit />
                </Styled.IconChangeImage>
              </label>
            )}
          </Styled.ContentText>
          <Styled.BoxButtonHeader>
            <Typography variant="h4" color="primary.main">
              {type !== listType.create
                ? _.get(information, 'fullName', '')
                : 'เพิ่มสมาชิกในทีม'}
            </Typography>
            {type === listType.view && (
              <Styled.BoxDetailIcon>
                {consentText !== '-' && (
                  <>
                    <Styled.IconStatus status={status} />
                    <Typography variant="body1b" sx={{ ml: -1 }}>
                      {consentText}
                    </Typography>
                  </>
                )}

                {/* {checkStatus === 'ACTIVE' && (
                  <Button
                    startIcon={
                      <img
                        alt="editSquareOutlined"
                        src="/icon/editSquareOutlined.svg"
                      />
                    }
                    variant="contained"
                    onClick={() => dispatch(handleEdit(history, uuid))}
                  >
                    <Typography variant="button" color="primary.contrast">
                      แก้ไขข้อมูลส่วนตัว
                    </Typography>
                  </Button>
                )} */}
              </Styled.BoxDetailIcon>
            )}
            {type !== listType.view && (
              <Styled.BoxDetailIcon>
                {type === listType.edit && consentText !== '-' && (
                  <>
                    <Styled.IconStatus status={status} />
                    <Typography variant="body1b">{consentText}</Typography>
                  </>
                )}
                {isNotSm && (
                  <FormButtons type={type} information={information} />
                )}
              </Styled.BoxDetailIcon>
            )}
          </Styled.BoxButtonHeader>
        </Styled.BoxImage>
      </Template>
    </HeaderCustom>
  )
}

export default Index

export const FormButtons = ({ type, information }) => {
  const history = useHistory()
  const { setValues } = useFormikContext()
  return (
    <>
      {type === listType.create && (
        <Button
          variant="outlined"
          sx={{ width: 100 }}
          onClick={() => history.push('/manage-prospect')}
        >
          <Typography variant="button" color="primary.min">
            ยกเลิก
          </Typography>
        </Button>
      )}
      <Button
        sx={{ width: 100 }}
        variant="contained"
        type="submit"
        onClick={() =>
          setValues({
            information: {
              ...information,
              dateOfBirth: _.get(information, 'dateOfBirth', null),
              institution: _.get(information, 'institution', ''),
              qualification: _.get(information, 'qualification', ''),
              between: _.get(information, 'between', ''),
              branch: _.get(information, 'branch', ''),
              gpa: _.get(information, 'gpa', ''),
            },
          })
        }
      >
        <Typography variant="button" color="primary.contrast">
          บันทึก
        </Typography>
      </Button>
    </>
  )
}
