import { getLearningState } from '../events'

const getTotalTime = (forceUpdate = false) => {
  const { INTERVAL_TIME } = getLearningState()
  const timeStored = +localStorage.getItem('timeStored') || 0
  const updateFailCount = +localStorage.getItem('updateFailCount') || 0
  const latestStampLog = +localStorage.getItem('latestStampLog')
  const newLatestStampLog = Date.now()

  const duration = forceUpdate
    ? Math.ceil((newLatestStampLog - latestStampLog) / 1000)
    : INTERVAL_TIME

  const totalTime = updateFailCount * +INTERVAL_TIME + timeStored + +duration // second
  return totalTime
}

export default getTotalTime
