import _ from 'lodash'
import { MODULE_TYPE } from 'src/constants/eLearning'
import { openErrorDialog } from 'src/redux/slices/alertDialog'
import { setTimeSpentLogUuid } from 'src/redux/slices/eLearning/learning'
import { postCreateTimeSpentLog } from 'src/services/eLearning/learning'
import { getLearningState } from '../events'

const initialTimeSpentLog = (refocus) => async (dispatch) => {
  const { __env__: env } = window

  if (env.ENABLE_OIC_EL !== 'true') return

  const { course, selectedLesson, logLearnerPerLessonList, timeSpentLogUuid } =
    getLearningState()
  const moduleType = _.get(selectedLesson, 'moduleType', '')
  const progress = _.get(selectedLesson, 'progress', 0)

  if (
    ![
      MODULE_TYPE.EMBED_CONTENT,
      MODULE_TYPE.LINK_TO,
      MODULE_TYPE.UPLOAD_FILE,
      MODULE_TYPE.SCORM,
    ].includes(moduleType) ||
    progress == 100 ||
    timeSpentLogUuid ||
    _.isEmpty(selectedLesson)
  )
    return
  const courseUuid = _.get(course, 'uuid', '')
  try {
    const user = JSON.parse(localStorage.getItem('user'))

    const courseCode = _.get(course, 'courseCode', '')
    const batchNo = _.get(
      course,
      'eLearningCourseBatchLearner.eLearningCourseBatch.sequence',
      0
    )
    const currentModule = logLearnerPerLessonList?.find(
      (e) => e.uuid === _.get(selectedLesson, 'logUuid', '')
    )
    const moduleCode = _.get(
      currentModule,
      'eLearningLessonRefModule.eLearningModule.moduleCode',
      ''
    )

    const createLogRes = await dispatch(
      postCreateTimeSpentLog({
        courseCode: courseCode,
        batchNo: batchNo,
        moduleCode: moduleCode,
        userUUID: _.get(user, 'uuid', ''),
      })
    )
    if (_.isNil(createLogRes.error)) {
      dispatch(setTimeSpentLogUuid(createLogRes.payload.data))
      localStorage.setItem('latestStampLog', Date.now())
      if (!refocus) {
        // ถ้าสร้าง log จากการ refocus ไม่ต้องเคลียร์ค่าออก
        localStorage.setItem('timeStored', 0)
        localStorage.setItem('updateFailCount', 0)
      }
    } else {
      throw new Error()
    }
  } catch (e) {
    dispatch(
      openErrorDialog({
        title: 'เกิดข้อผิดพลาด',
        message: ['กรุณาลองใหม่อีกครั้ง'],
        buttonRight: {
          handleClick: () =>
            (window.location.href = `/e-learning/course/${courseUuid}`),
        },
      })
    )
  }
}
export default initialTimeSpentLog
