import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import ArrowForward from '@mui/icons-material/ArrowForward'
import screenfull from 'screenfull'
import { store } from 'src/App'
import {
  setFullScreen,
  setProgressPercent,
} from 'src/redux/slices/eLearning/learning'
import { moduleCompletedStatus } from 'src/redux/slices/eLearning/learning/events'
import { Column } from 'src/components/Layout/Styled'
import { path } from 'src/constants/path'
import { getLearningState } from '../events'

export const handleProgressChange = (lessons) => (dispatch) => {
  const { isCourseCompleted, progressPercent } = getLearningState()
  if (isCourseCompleted) return

  const logs = []
  _.forEach(lessons, (lesson) => {
    _.forEach(lesson.modules, (module) => {
      logs.push(module.result)
    })
  })
  const totalInprogress = logs.filter((item) =>
    moduleCompletedStatus.includes(item)
  ).length
  const percent = (totalInprogress / logs.length) * 100
  const payload = _.isNaN(percent) ? 0 : percent.toFixed()
  const newProgressPercent = Number(payload)

  if (Number(progressPercent) !== newProgressPercent) {
    dispatch(setProgressPercent(newProgressPercent))
  }
}

export const handleError409 = (error, setAlertDialog) => {
  if (_.isEmpty(error)) return

  const constraints = _.get(error, 'error.constraints', {})
  const errorStatus = _.get(error, 'error.status', 404)
  const dialogProp = {
    isDialogOpen: true,
    iconType: 'error',
    title: _.get(constraints, 'title', 'ผิดพลาด'),
    message: _.get(constraints, 'value', ['ดำเนินการไม่สำเร็จ']),
    buttonRight:
      errorStatus === 400
        ? { handleClick: () => window.location.reload() }
        : {},
  }
  setAlertDialog((prev) => ({ ...prev, ...dialogProp }))
}

export const handleAlertComplete = (completeResult, setAlertDialog) => {
  const { isCourseCompleted, eLearningCourseBatchLearner } = getLearningState()
  if (_.isEmpty(completeResult) || isCourseCompleted) return

  if (screenfull?.isEnabled) {
    if (screenfull.isFullscreen) {
      screenfull
        .exit()
        .then(() => {
          console.log('Exited fullscreen')
        })
        .catch((err) => {
          console.error('Failed to exit fullscreen:', err)
        })
    }
  } else {
    console.error('Fullscreen mode is not supported.')
  }
  store.dispatch(setFullScreen(false))

  const dialogProp = {
    isDialogOpen: true,
    content: (
      <Column alignItems="center" gap={3}>
        <img src="/icon/e-learning/ic_complete.svg" width={190} />
        <Typography variant="h6">คุณผ่านการอบรมเรียบร้อยแล้ว</Typography>
      </Column>
    ),
    buttonRight: {
      label: 'สรุปผลการเรียน',
      endIcon: <ArrowForward />,
      handleClick: () =>
        (window.location.href = `${path.myClassELearning}/${eLearningCourseBatchLearner.uuid}`),
    },
  }
  setAlertDialog((prev) => ({ ...prev, ...dialogProp }))
}
