import dayjs from 'dayjs'
import { putUpdateTimeSpentLog } from 'src/services/eLearning/learning'
import { getLearningState } from '../events'
import _ from 'lodash'
import clearTimeSpentLogInterval from './clearTimeSpentLogInterval'
import getTotalTime from './getTotalTime'

const updateTimeSpentlog =
  (forceUpdate = false, oldProgress) =>
  async (dispatch) => {
    const { selectedLesson, MAXIMUM_RETRY, timeSpentLogUuid } =
      getLearningState()
    const updateFailCount = localStorage.getItem('updateFailCount') || 0
    const newLatestStampLog = Date.now()
    const totalTime = getTotalTime(forceUpdate)

    const body = {
      uuid: timeSpentLogUuid,
      pendingDuration: Math.ceil(totalTime / 60),
      intervalDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss').toString(),
      endDateTime: '',
      percentProgress: forceUpdate
        ? oldProgress || 0
        : _.get(selectedLesson, 'progress', 0),
    }
    const updateLogRes = await dispatch(putUpdateTimeSpentLog(body))

    if (_.isNil(updateLogRes.error) && updateLogRes?.payload?.data) {
      // update สำเร็จ reset ค่าใน local
      localStorage.setItem('latestStampLog', newLatestStampLog)
      localStorage.setItem('timeStored', 0)
      localStorage.setItem('updateFailCount', 0)
    } else {
      // update ไม่สำเร็จ ให้ retry update
      let count = 0
      let res = false
      // retry ทุกๆ 30 วินาที
      const interval = setInterval(async () => {
        count++
        if (
          checkStopRetryUpdate(
            count,
            +MAXIMUM_RETRY,
            res,
            _.get(getLearningState(), 'selectedLesson.progress', 0)
          )
        ) {
          // Stop after maximum retry or update success or progress is 100
          clearTimeSpentLogInterval('retryIntervalId')

          if (res) {
            // retry สำเร็จ reset ค่าใน local
            localStorage.setItem('latestStampLog', newLatestStampLog)
            localStorage.setItem('timeStored', 0)
            localStorage.setItem('updateFailCount', 0)
          } else {
            // retry ไม่สำเร็จ บวกจำนวนที่ fail สำหรับทบเวลาตอนอัปเดตครั้งต่อไป
            localStorage.setItem('latestStampLog', newLatestStampLog)
            localStorage.setItem('updateFailCount', updateFailCount + 1)
          }
          return
        }

        const updateRes = await dispatch(putUpdateTimeSpentLog(body))
        res = _.isNil(updateRes.error) && updateRes?.payload?.data
      }, 30000)
      localStorage.setItem('retryIntervalId', interval)
    }
  }

export default updateTimeSpentlog

const checkStopRetryUpdate = (
  count,
  MAXIMUM_RETRY,
  updateComplete,
  progress
) => {
  const reachMaximumRetry = count === MAXIMUM_RETRY
  const moduleComplete = progress == 100

  return reachMaximumRetry || updateComplete || moduleComplete
}
