import React from 'react'
import _ from 'lodash'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Refresh from '@mui/icons-material/Refresh'
import screenfull from 'screenfull'
import { store } from 'src/App'
import { path } from 'src/constants/path'
import { handleAnswerAmount as handleAnswerAmountEva } from 'src/modules/EEvaluation/Evaluate/Footer/events'
import { handleAnswerAmount } from 'src/modules/ETesting/Testing/Footer/events'
import {
  E_LEARNING_LEARNER_LOG_RESULT,
  MODULE_TYPE,
} from 'src/constants/eLearning'
import { putCourseLogLearnerPerLesson } from 'src/services/eLearning/learning'
import { closeDialog, openDialog, openErrorDialog } from '../../alertDialog'
import { initialVideo } from './model'
import {
  setFullScreen,
  setSelectedLesson,
  setVideoState,
  startLoading,
  stopLoading,
} from '.'
import handleTimeSpentLogData from 'src/modules/ELearning/Course/Learning/handler/handleTimeSpentLogData'

export const moduleCompletedStatus = [
  E_LEARNING_LEARNER_LOG_RESULT.COMPLETE,
  E_LEARNING_LEARNER_LOG_RESULT.PASS,
]

export const handleInitialProgress = (payload) => {
  const logs = _.get(payload, 'logs', [])
  const totalInprogress = logs.filter((item) =>
    moduleCompletedStatus.includes(item.data?.result)
  ).length
  const percent = (totalInprogress / logs.length) * 100
  const progressPercent = _.isNaN(percent) ? 0 : percent.toFixed()
  return Number(progressPercent)
}

export const handleInitialCourse = (payload) => {
  const isSkipAllowed = _.get(payload.setting, 'isSkipAllowed', false)
  const logs = _.get(payload, 'logs', [])

  return _.map(payload.eLearningCourseLesson, (lesson, index) => {
    const eLearningLessonRefModule = lesson.eLearningLessonRefModule || []
    const modules = _.map(eLearningLessonRefModule, (item, mIndex) => {
      const refModuleUuidKey = 'eLearningLessonRefModule.uuid'
      const log = logs.find(
        (log) => _.get(log, refModuleUuidKey, '') === item.uuid
      )
      const logObj = { ..._.get(log, 'data', {}), uuid: _.get(log, 'uuid', '') }
      const refModule = { ...item, log: logObj }
      const initModule = handleInitialModule(refModule, mIndex, index)
      const isLatestLearn = _.get(logs[0], refModuleUuidKey, '') === item.uuid
      return {
        ...initModule,
        isLatestLearn,
        eLearningLessonRefModule: { uuid: item.uuid, id: item.id },
        eLearningModule: {
          uuid: item.eLearningModule.uuid,
          id: item.eLearningModule.id,
        },
      }
    })
    return {
      ...lesson,
      eLearningLessonRefModule: undefined,
      modules: isSkipAllowed
        ? modules
        : handleDisableModule(logs, modules, index),
    }
  })
}

export const handleDisableModule = (logs, modules, index) => {
  logs = _.orderBy(logs, ['lessonSequence', 'moduleSequence'])
  return _.map(modules, (module, mIndex) => {
    const isFirstModule = index === 0 && mIndex === 0
    const logIndex = logs.findIndex((log) => log.uuid === module.logUuid)
    if (logIndex === -1) return { ...module, disabled: true }

    const previousLogResult = _.get(logs[logIndex - 1], 'data.result', '')
    const previousResult = _.get(modules[mIndex - 1], 'result', '')
    const isPreviousComplete =
      isFirstModule ||
      moduleCompletedStatus.includes(previousLogResult) ||
      moduleCompletedStatus.includes(previousResult)
    const isComplete = moduleCompletedStatus.includes(module.result)
    const isLastModule = logIndex === logs.length - 1
    const isLatestLearn = isComplete ? isLastModule : isPreviousComplete
    return {
      ...module,
      isLatestLearn,
      disabled: !isFirstModule && !isPreviousComplete && !isComplete,
    }
  })
}

export const handleInitialModule = (refModule, mIndex, index) => {
  const eLearningModule = _.get(refModule, 'eLearningModule', {})
  const moduleType = _.get(eLearningModule, 'moduleType', '')
  const log = _.get(refModule, 'log', {})
  const result = _.get(log, 'result', '')
  const startTime = _.get(log, 'startTime', '')
  const endTime = _.get(log, 'endTime', '')
  const isInProgress = result === E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS

  let module = {
    lessonNo: index + 1,
    no: mIndex + 1,
    id: _.get(eLearningModule, 'id', ''),
    uuid: _.get(eLearningModule, 'uuid', ''),
    lessonName: _.get(eLearningModule, 'name', ''),
    type: moduleType,
    moduleType,
    logUuid: _.get(log, 'uuid', ''),
    progress: _.get(log, 'percent', 0),
    result,
  }

  switch (moduleType) {
    case MODULE_TYPE.E_TESTING: {
      const eTestingUuid = _.get(eLearningModule, 'moduleDetail.uuid', '')
      const isSubmitted =
        result === E_LEARNING_LEARNER_LOG_RESULT.PASS ||
        result === E_LEARNING_LEARNER_LOG_RESULT.FAIL
      localStorage.removeItem(eTestingUuid)
      module = {
        ...module,
        eTestingUuid,
        eTestingType: _.get(eLearningModule, 'moduleDetail.type', ''),
        criteriaType: _.get(eLearningModule, 'moduleDetail.criteriaType', ''),
        criteriaPercent: _.get(
          eLearningModule,
          'moduleDetail.criteriaPercent',
          ''
        ),
        startTime,
        endTime,
        isInProgress,
        isSubmitted,
      }
      break
    }
    case MODULE_TYPE.E_EVALUATION: {
      const eEvaluationUuid = _.get(eLearningModule, 'moduleDetail.uuid', '')
      const isSubmitted = result === E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
      localStorage.removeItem(eEvaluationUuid)
      module = {
        ...module,
        eEvaluationUuid,
        eEvaluationType: _.get(eLearningModule, 'moduleDetail.type', ''),
        criteriaType: _.get(eLearningModule, 'moduleDetail.criteriaType', ''),
        startTime,
        endTime,
        isInProgress,
        isSubmitted,
      }
      break
    }
    case MODULE_TYPE.EMBED_CONTENT:
      module = {
        ...module,
        type: _.get(eLearningModule, 'moduleDetail.type', ''),
        url: _.get(eLearningModule, 'moduleDetail.value', ''),
        duration: _.get(eLearningModule, 'moduleDetail.duration', 0),
        playedPercent: _.get(log, 'playedPercent', 0),
        videoState: {
          ...initialVideo,
          playedSeconds: _.get(log, 'duration', 0),
        },
      }
      break
    case MODULE_TYPE.LINK_TO:
      module = {
        ...module,
        link: _.get(eLearningModule, 'moduleDetail.link', ''),
        linkDescription: _.get(
          eLearningModule,
          'moduleDetail.linkDescription',
          ''
        ),
      }
      break
    case MODULE_TYPE.UPLOAD_FILE:
      {
        const fileList = _.get(eLearningModule, 'eLearningModuleFileUpload', [])
          .filter((file) => _.isEmpty(file.error))
          .map((file) => {
            const logFile = _.get(log, 'files', []).find(
              (log) => log.uuid === file.uuid
            )
            const percent = _.get(logFile, 'percent', 0)
            return {
              ...file,
              process: percent,
              isFinished: percent === 100,
            }
          })
        module = { ...module, selectedFile: 0, fileList }
      }
      break
    case MODULE_TYPE.SCORM: {
      const scormData = _.get(log, 'scormData', {})
      module = { ...module, ...eLearningModule, type: moduleType, scormData }
      break
    }
    default:
      break
  }

  return module
}

export const handleSetLessonState = (state, module) => {
  state.selectedLesson = module
  const lessonIndex = _.get(state.selectedLesson, 'lessonNo', 1) - 1
  const moduleIndex = _.get(state.selectedLesson, 'no', 1) - 1
  _.set(state.lessons, `[${lessonIndex}].modules[${moduleIndex}]`, module)
}

export const handleEmbedContentProgress = (payload) => {
  const played = _.get(payload, 'played', 0)
  return (played * 100).toFixed()
}

export const isProgressDiff = (listenerApi, progress) => {
  const { selectedLesson } = listenerApi.getOriginalState().eLearningLearning
  const previousProgress = _.get(selectedLesson, 'progress', '0')
  return Number(previousProgress) !== Number(progress)
}

export const handleUploadFileProgress = (selectedLesson) => {
  const fileList = _.get(selectedLesson, 'fileList', [])
  const seletedFiles = fileList.filter((item) => item.isFinished)
  const progress = (seletedFiles.length / fileList.length) * 100
  return progress.toFixed()
}

export const handleETestingProgress = (eTestingProps) => {
  if (eTestingProps.section === 'RESULT' || eTestingProps.isReview) {
    return 100
  }

  const { totalETesting } = eTestingProps
  const answerAmount = Number(handleAnswerAmount(eTestingProps))
  if (_.isNaN(answerAmount)) return 0
  return (answerAmount / totalETesting) * 100
}

export const handleEEvaluationProgress = (eEvaluationProps) => {
  if (eEvaluationProps.section === 'RESULT') return 100
  const answerAmount = Number(
    handleAnswerAmountEva({
      ...eEvaluationProps,
      setting: { displayQuestion: 'ALL' },
    })
  )
  return _.isNaN(answerAmount) ? 0 : answerAmount
}

export const getLatestETestEEva = (listenerApi) => {
  const { eLearningLearning } = listenerApi.getState()
  const selected = _.get(eLearningLearning, 'selectedLesson', {})
  const eLearningLessonRefModule = _.get(
    selected,
    'eLearningLessonRefModule',
    {}
  )
  const uuid = _.get(eLearningLessonRefModule, 'uuid', '')
  const latestState = JSON.parse(localStorage.getItem(uuid))
  return latestState
}

export const setLatestETestEEva = (listenerApi, key) => {
  const eTestEEvaState = listenerApi.getState()[key]
  const oldELearning = listenerApi.getOriginalState().eLearningLearning
  const oldSelected = _.get(oldELearning, 'selectedLesson', {})
  const eLearningLessonRefModule = _.get(
    oldSelected,
    'eLearningLessonRefModule',
    {}
  )
  const uuid = _.get(eLearningLessonRefModule, 'uuid', '')
  const eUuid = _.get(oldSelected, `${key}Uuid`, '')

  if (eTestEEvaState.uuid === eUuid) {
    const eLearningETestEEvaState = JSON.stringify(eTestEEvaState)
    localStorage.setItem(uuid, eLearningETestEEvaState)
  }
}

export const handleUpdateLogLearnerPerLesson = (body) => (dispatch) => {
  const {
    selectedLesson,
    eLearningCourseBatchLearner,
    logLearnerPerLessonList,
  } = store.getState().eLearningLearning
  const eLearningLessonRefModule = _.get(
    selectedLesson,
    'eLearningLessonRefModule',
    {}
  )
  const timeSpentLog = handleTimeSpentLogData(body.data.percent)
  const payload = {
    ...body,
    eLearningCourseBatchLearner,
    eLearningLessonRefModule,
    logLearnerPerLessonList,
    timeSpentLog,
  }
  dispatch(handlePutLogLearnerPerLesson(payload))
}

export const handlePutLogLearnerPerLesson = (body) => async (dispatch) => {
  if (_.isEmpty(body.logLearnerPerLessonUuid)) return

  const response = await dispatch(putCourseLogLearnerPerLesson(body))
  if (!_.isEmpty(response.error)) {
    dispatch(handleError409(response))
    dispatch(setVideoState({ playing: false }))
  }
}

export const handleError409 = (response) => (dispatch) => {
  const error = _.get(response, 'meta.response', {})
  const constraints = _.get(error, 'data.constraints', {})
  const errorStatus = _.get(error, 'status', 404)
  dispatch(
    openErrorDialog({
      iconType: 'error',
      title: _.get(constraints, 'title', 'ผิดพลาด'),
      message: _.get(constraints, 'value', ['ดำเนินการไม่สำเร็จ']),
      buttonRight:
        errorStatus === 400
          ? { handleClick: () => window.location.reload() }
          : {},
    })
  )
}

export const handleRelationObj = (obj) => {
  if (_.isEmpty(obj)) return null
  else return { id: obj.id, uuid: obj.uuid }
}

export const handleCheckLessonComplete = () => (dispatch) => {
  const { selectedLesson, lessons } = store.getState().eLearningLearning
  const logs = []
  lessons.forEach((lesson) =>
    lesson.modules?.forEach((module) => logs.push(module.result))
  )
  const totalInprogress = logs.filter(
    (item) => !moduleCompletedStatus.includes(item)
  ).length
  const isLastLog = totalInprogress <= 1
  if (isLastLog) return

  const lessonNo = _.get(selectedLesson, 'lessonNo', 1)
  const moduleNo = _.get(selectedLesson, 'no', 1)
  const nowModuleLesson = _.get(lessons, `[${lessonNo - 1}].modules`, [])
  const isLastLesson = lessons.length === lessonNo
  const isLastModule = nowModuleLesson.length === moduleNo
  const isLastModuleAndLesson = isLastLesson && isLastModule
  if (isLastModuleAndLesson) return

  dispatch(handleLessonComplete())
}

export const handleLessonComplete = () => (dispatch) => {
  if (screenfull?.isEnabled) {
    if (screenfull.isFullscreen) {
      screenfull
        .exit()
        .then(() => {
          console.log('Exited fullscreen')
        })
        .catch((err) => {
          console.error('Failed to exit fullscreen:', err)
        })
    }
  } else {
    console.error('Fullscreen mode is not supported.')
  }
  dispatch(setFullScreen(false))

  const { course } = store.getState().eLearningLearning
  dispatch(
    openDialog({
      iconType: 'success',
      title: 'จบบทเรียน',
      message: [
        'ขณะนี้คุณสามารถไปยังบทเรียนถัดไป เรียนซ้ำอีกครั้ง หรือกลับสู่หน้าหลัก',
      ],
      buttonLeft: {
        label: 'กลับสู่หน้าหลัก',
        handleClick: () => {
          dispatch(closeDialog())
          window.location.href = `${path.eLearningCourse}/${course.uuid}`
        },
      },
      buttonRight: {
        label: 'ไปยังบทเรียนถัดไป',
        endIcon: <ArrowForward />,
        style: { minWidth: 154, p: '12px' },
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(handleGoToNextLesson())
        },
      },
      buttonCenter: {
        label: 'เรียนซ้ำอีกครั้ง',
        endIcon: <Refresh />,
        style: { width: '100%', minWidth: 146, p: '12px' },
        color: 'secondary',
        variant: 'outlined',
        handleClick: () => store.dispatch(closeDialog()),
      },
      px: '18px',
    })
  )
}

export const handleGoToNextLesson = () => (dispatch) => {
  const { selectedLesson, lessons } = store.getState().eLearningLearning
  const lIndex = _.get(selectedLesson, 'lessonNo', 1) - 1
  const mIndex = _.get(selectedLesson, 'no', 1) - 1
  const nextModule = _.get(lessons[lIndex], `modules[${mIndex + 1}]`, null)

  dispatch(startLoading())
  setTimeout(() => {
    if (!_.isEmpty(nextModule)) {
      dispatch(setSelectedLesson(nextModule))
    } else {
      const nextLessonModule = _.get(lessons[lIndex + 1], 'modules[0]', null)
      if (!_.isEmpty(nextLessonModule)) {
        dispatch(setSelectedLesson(nextLessonModule))
      }
    }
    dispatch(stopLoading())
  })
}
