import _ from 'lodash'
import { E_LEARNING_LEARNER_LOG_RESULT } from 'src/constants/eLearning'
import { setModuleProgress } from 'src/redux/slices/eLearning/learning'
import { handlePutLogLearnerPerLesson } from 'src/redux/slices/eLearning/learning/events'
import { getLearningState } from '../../../events'
import handleTimeSpentLogData from '../../../handler/handleTimeSpentLogData'

export const getSettingsFromParams = (urlParams) => {
  const settings = {
    autocommit: true,
    autocommitSeconds: 30,
    dataCommitFormat: 'json',
    commitRequestDataType: 'application/json;charset=UTF-8',
    autoProgress: false,
    logLevel: 1,
    mastery_override: false,
  }

  if (urlParams.get('autocommit') !== null) {
    settings.autocommit = urlParams.get('autocommit') === 'true'
  }

  if (urlParams.get('autocommitSeconds') !== null) {
    let seconds = parseInt(urlParams.get('autocommitSeconds'))
    if (isNaN(seconds)) {
      seconds = 60 // default
    }
    settings.autocommitSeconds = seconds
  }

  if (urlParams.get('dataCommitFormat') !== null) {
    const format = urlParams.get('dataCommitFormat')
    if (format !== null) {
      if (format === 'json' || format === 'params' || format === 'flattened') {
        settings.dataCommitFormat = format
      }
    }
  }

  if (urlParams.get('logLevel') !== null) {
    let level = parseInt(urlParams.get('logLevel'))
    if (isNaN(level) || level < 1 || level > 5) {
      level = 4 // default
    }
    settings.logLevel = level
  }

  return settings
}

export const checkScormLastStatus = (version) => {
  const { selectedLesson } = getLearningState()
  const oldScormData = _.get(selectedLesson, 'scormData', {})

  let completionStatus
  if (version === '1.2') {
    completionStatus = _.get(
      oldScormData,
      'cmi.core.lesson_status',
      'incomplete'
    )
  }
  if (version === '2004') {
    completionStatus = _.get(
      oldScormData,
      'cmi.completion_status',
      'incomplete'
    )
  }
  return completionStatus
}

export const handleUpdateScormLog = (response, version) => async (dispatch) => {
  const {
    selectedLesson,
    eLearningCourseBatchLearner,
    logLearnerPerLessonList,
  } = getLearningState()

  if (selectedLesson.result === E_LEARNING_LEARNER_LOG_RESULT.COMPLETE) return

  const eLearningLessonRefModule = _.get(
    selectedLesson,
    'eLearningLessonRefModule',
    {}
  )
  const oldScormData =
    typeof selectedLesson === 'object'
      ? _.get(selectedLesson, 'scormData', {})
      : JSON.parse(_.get(selectedLesson, 'scormData', {}))

  if (_.isEqual(oldScormData, response)) return

  const completionStatus =
    version === '1.2'
      ? _.get(response, 'cmi.core.lesson_status', '')
      : _.get(response, 'cmi.completion_status', '')
  const interactions = _.values(_.get(response, 'cmi.interactions', {}))
  console.warn('interactions >>>>>', interactions)

  let percent = 0
  let result = E_LEARNING_LEARNER_LOG_RESULT.PENDING
  if (interactions.length > 0) {
    result = E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS
  }
  if (completionStatus === 'completed' || completionStatus === 'passed') {
    result = E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
    percent = 100
    dispatch(setModuleProgress(percent))
  }
  const data = { percent, result, scormData: response }
  const timeSpentLog = handleTimeSpentLogData(data.percent)
  const body = {
    eLearningCourseBatchLearner,
    eLearningLessonRefModule,
    logLearnerPerLessonUuid: _.get(selectedLesson, 'logUuid', ''),
    data,
    logLearnerPerLessonList,
    timeSpentLog,
  }
  console.warn('data >>>>>', data)

  await dispatch(handlePutLogLearnerPerLesson(body))
}
