import dayjs from 'dayjs'
import { getLearningState } from '../events'
import clearTimeSpentLogInterval from './clearTimeSpentLogInterval'
import _ from 'lodash'
import getTotalTime from './getTotalTime'

const handleTimeSpentLogData = (progress) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const { timeSpentLogUuid, course } = getLearningState()
  const courseCode = _.get(course, 'courseCode', '')
  const totalTime = getTotalTime(true)

  if (progress == 100) {
    clearTimeSpentLogInterval('updateIntervalId')
  }
  return {
    uuid: timeSpentLogUuid || '',
    pendingDuration: Math.ceil(totalTime / 60),
    intervalDateTime: '',
    endDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss').toString(),
    percentProgress: progress,
    userUUID: _.get(user, 'uuid', ''),
    courseCode: courseCode,
  }
}

export default handleTimeSpentLogData
