import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Webcam from 'react-webcam'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Row } from 'src/components/Layout/Styled'
import { handleFocus } from '../events'
import { StyledCameraFrame, StyledCaptureButton, StyledTooltip } from './Styled'

const CameraFrame = ({ field, image, isShowTooltip, onCapture, onError }) => {
  const camRef = useRef(null)
  const isLg = isMediaWidthMoreThan('md')
  const isNotSm = isMediaWidthMoreThan('sm')
  const isTablet = !isLg && isNotSm
  const videoWidth = isNotSm ? 500 : 294
  const videoHeight = isTablet ? 665 : 369
  const [facingMode, setFacingMode] = useState({ exact: 'environment' })
  const [deviceId, setDeviceId] = useState(undefined)
  const videoConstraints = useMemo(
    () => ({
      width: videoWidth,
      height: videoHeight,
      facingMode,
      deviceId,
    }),
    [facingMode, deviceId]
  )
  const frameImg = field === 'idCardImage' ? 'id_card' : 'face'

  useEffect(() => {
    handleFocus(camRef, setDeviceId, setFacingMode)
  }, [])

  return (
    <>
      <Box sx={{ position: 'relative', height: 'auto' }}>
        {image ? (
          <img
            src={image}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <Webcam
            ref={camRef}
            audio={false}
            width="100%"
            height={videoHeight}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            screenshotQuality={1}
            onUserMedia={() => handleFocus(camRef, setDeviceId)}
            onUserMediaError={onError}
          />
        )}
        <StyledCameraFrame src={`/icon/e-learning/frame_cam_${frameImg}.svg`} />
      </Box>
      <Row sx={{ justifyContent: 'center', position: 'relative' }}>
        <StyledCaptureButton onClick={() => onCapture(camRef)}>
          <img src="/icon/e-learning/ic_camera.svg" />
        </StyledCaptureButton>

        <CameraOption
          isShowTooltip={isShowTooltip}
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          setFacingMode={setFacingMode}
        />
      </Row>
    </>
  )
}

export default CameraFrame

export const CameraOption = ({
  isShowTooltip = false,
  deviceId,
  setDeviceId,
  setFacingMode,
}) => {
  const [isShow, setShow] = useState(isShowTooltip)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [devices, setDevices] = useState([])
  const handleDevices = useCallback(
    (mediaDevices) => {
      const inputs = mediaDevices.filter(({ kind }) => kind === 'videoinput')
      setDevices(inputs)
    },
    [setDevices]
  )

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [navigator, handleDevices])

  useEffect(() => {
    const handleClickOutside = () => {
      if (isShowTooltip && isShow) setShow(false)
    }

    const cameraDialog = document.getElementById('camera-dialog')
    cameraDialog.addEventListener('click', handleClickOutside)
    return () => {
      cameraDialog.removeEventListener('click', handleClickOutside)
    }
  }, [isShowTooltip])

  return (
    <div style={{ position: 'absolute', right: 32 }}>
      <StyledTooltip
        title="กดเพื่อแก้ไขการตั้งค่ากล้อง"
        open={isShow}
        onOpen={() => setShow(true)}
        onClose={() => setShow(false)}
      >
        <IconButton
          id="basic-button"
          variant="outlined"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <img src="/icon/ic_camera_setting.svg" />
        </IconButton>
      </StyledTooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {devices.map((device, index) => (
          <MenuItem
            key={index}
            selected={deviceId === device.deviceId}
            onClick={() => {
              setDeviceId(device.deviceId)
              setFacingMode(undefined)
              handleClose()
            }}
          >
            {device.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
