import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import CheckCircle from '@mui/icons-material/CheckCircle'
import IconButton from '@mui/material/IconButton'
import ZoomIn from '@mui/icons-material/ZoomIn'
import ZoomOut from '@mui/icons-material/ZoomOut'
import RestartAlt from '@mui/icons-material/RestartAlt'
import People from '@mui/icons-material/People'
import { setExpand } from 'src/redux/slices/eLearning/learning'
import { COURSE_TYPE, MODULE_TYPE } from 'src/constants/eLearning'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleFinishFile } from '../../LeftPanel/MediaDisplay/FileRender/events'
import { handleHoverOnVideo } from '../../LeftPanel/events'
import { StyledFloatButton } from '../../Styled'
import { StyledButtonWrapper, StyledToolWrapper, StyledFloatBg } from './Styled'

const FloatingButtons = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const ref = useRef(null)
  const { isExpanded, courseType } = useSelector(
    (state) => ({
      isExpanded: state.eLearningLearning.isExpanded,
      courseType: _.get(state.eLearningLearning.course, 'courseType', ''),
    }),
    shallowEqual
  )
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    handleHoverOnVideo(ref)
  }, [])

  return (
    <StyledFloatBg ref={ref}>
      <StyledFloatButton
        sx={{ left: 28 }}
        size="small"
        startIcon={<ArrowBackIosNew />}
        onClick={() => history.push(`/e-learning/course/${uuid}`)}
      >
        กลับไปหน้าหลัก
      </StyledFloatButton>

      <FileModuleButtons />

      {isNotSm && courseType === COURSE_TYPE.OIC && (
        <StyledFloatButton
          size="small"
          variant="outlined"
          sx={{ right: isExpanded && isLg ? 440 : 133 }}
          startIcon={<People />}
          onClick={() =>
            document
              ?.getElementById('question-section')
              ?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          ตั้งคำถาม / พูดคุย
        </StyledFloatButton>
      )}

      <StyledFloatButton
        size="small"
        variant="outlined"
        sx={{ right: 28 }}
        startIcon={<img src="/icon/e-learning/player/expand_menu.svg" />}
        onClick={() => dispatch(setExpand(true))}
      >
        บทเรียน
      </StyledFloatButton>
    </StyledFloatBg>
  )
}

export default FloatingButtons

export const FileModuleButtons = () => {
  const isLg = isMediaWidthMoreThan('lg')
  const { type, fileRef } = useSelector(
    (state) => ({
      type: _.get(state.eLearningLearning.selectedLesson, 'type', ''),
      fileRef: _.get(state.eLearningLearning.selectedLesson, 'fileRef', {}),
    }),
    shallowEqual
  )
  const isFile = type === MODULE_TYPE.UPLOAD_FILE
  if (!isFile) return <></>
  return (
    <>
      {isLg && !_.isEmpty(fileRef.current) && (
        <StyledToolWrapper>
          <IconButton color="primary" onClick={() => fileRef.current?.zoomIn()}>
            <ZoomIn />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => fileRef.current?.zoomOut()}
          >
            <ZoomOut />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => fileRef.current?.resetTransform()}
          >
            <RestartAlt />
          </IconButton>
        </StyledToolWrapper>
      )}

      <FinishedFileButton />
    </>
  )
}

export const FinishedFileButton = ({ isFixed }) => {
  const { isExpanded, selectedLesson, courseType } = useSelector(
    (state) => ({
      isExpanded: state.eLearningLearning.isExpanded,
      selectedLesson: state.eLearningLearning.selectedLesson,
      courseType: _.get(state.eLearningLearning.course, 'courseType', ''),
    }),
    shallowEqual
  )
  const selectedFile = _.get(selectedLesson, 'selectedFile', 0)
  const fileList = _.get(selectedLesson, 'fileList', [])
  const isFinished = _.get(fileList, `[${selectedFile}].isFinished`, false)
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')

  if (isFinished) return <></>

  if (isFixed && !isNotSm) {
    return (
      <StyledButtonWrapper>
        <Button
          size="small"
          variant="contained"
          startIcon={<CheckCircle />}
          onClick={handleFinishFile}
        >
          เรียนบทนี้จบแล้ว
        </Button>
      </StyledButtonWrapper>
    )
  }

  if (!isFixed && isNotSm) {
    let right = isExpanded && isLg ? 596 : 290
    if (courseType !== COURSE_TYPE.OIC) right = right - 155
    return (
      <StyledFloatButton
        size="small"
        variant="contained"
        startIcon={<CheckCircle />}
        sx={{ right }}
        onClick={handleFinishFile}
      >
        เรียนบทนี้จบแล้ว
      </StyledFloatButton>
    )
  }

  return <></>
}
