import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Redirect, useLocation } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import Appbar from './components/Appbar'
import Footer from './components/Footer'
import FooterDMS from './components/Footer/FooterDms'
import { handleExceptPath } from './events'
import UpdateProfileDialog from './components/UpdateProfileDialog'

const Layout = (props) => {
  const [, setPreviousPath] = useLocalStorage('previousPath')

  const user = localStorage.getItem('user')
  const lastLocation = useLocation()
  const from = _.get(props, 'location', '/')
  const content = _.get(props, 'children', <></>)

  if (props.layout === 'login') {
    if (user) {
      return <Redirect to={{ pathname: '/', state: { from } }} />
    }
    return <RenderContent content={content} />
  }

  const redirectTo = `${lastLocation.pathname}${lastLocation.search}`
  if (user || handleExceptPath(lastLocation)) {
    const inOf = redirectTo.indexOf('?state')
    const inCode = redirectTo.indexOf('?code')
    if (inOf === 1 || inCode === 1) {
      return <Redirect to={{ pathname: '/', state: { from } }} />
    }
    return <RenderContent content={content} />
  } else {
    if (lastLocation.pathname !== '/login') {
      setPreviousPath(redirectTo)
    }
    return <Redirect to={{ pathname: '/login', state: { from } }} />
  }
}

export default Layout

export const RenderContent = ({ content }) => {
  const location = useLocation()
  const [user] = useLocalStorage('user')
  const [open, setOpen] = useState(false)
  const isLearningPath = location.pathname.includes('/course/learning')

  const [stateDms, setStateDms] = useState(true)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const param = searchParams.get('previousPath')

  const loginPath = location.pathname.includes('/login')
  useEffect(() => {
    if (loginPath) {
      setStateDms(true)
    } else {
      setStateDms(false)//fixed dms login
    }
  }, [param])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!handleExceptPath(location) && !user.isProfileUpdated) {
      setOpen(true)
    }
  }, [])

  return (
    <>
      {!isLearningPath && <Appbar />}
      {content}
      {!isLearningPath && !stateDms && <Footer />}
      {!isLearningPath && stateDms && <FooterDMS />}
      <UpdateProfileDialog isOpen={open} onClose={() => setOpen(false)} />
    </>
  )
}
