import React from 'react'
import { Route, Switch } from 'react-router-dom'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { openDialog } from './redux/slices/alertDialog'
import Box from '@mui/material/Box'
import Template from './components/Template'

import Layout from './Layout'

import LoginPage from './modules/Login'
import HomePage from './pages/home'
import ForgetPasswordPage from './pages/forget-password'
import LocationPage from './pages/location'

// course
import CoursePage from './pages/course'
import CourseDetailPage from './pages/course/detail/[id]'
import ClassDetailPage from './pages/class/[id]'
import MyClassPage from './pages/my-class'
import MyClassOldPage from './pages/my-class/old'
// E-Evaluation
import EEvaluation from './pages/e-evaluation/[id]'
import EEvaluationIndex from './pages/e-evaluation'
import EEvaluationEvaluate from './pages/e-evaluation/evaluate/[id]'
// E-Testing
import ETesting from './pages/e-testing/[id]'
import ETestingIndex from './pages/e-testing'
import ETestingTesting from './pages/e-testing/testing/[id]'
import ETestingReview from './pages/e-testing/review/[id]'
// document
import DocumentPage from './pages/document/[id]'

// CheckIn
import CheckIn from './pages/check-in/[id]'

// TeamProfile

// manage prospect
import ManageProspectPage from './pages/manage-prospect'
import ManageProspectForm from './pages/manage-prospect/form/'
import ManageProspectFormEdit from './pages/manage-prospect/form/[id]'
import ManageProspectFormView from './pages/manage-prospect/form/view/[id]'

import EnrollmentPage from './pages/manage-prospect/enrollment'
import AdditionalInfoConsentPage from './pages/additional-info/consent/[uuid]'
import AdditionalInfoConsentAndDocsPage from './pages/additional-info/consent/upload-document/[uuid]'
import RegistrationDetailPage from './pages/my-class/registration-detail/[id]'
import ELearningRegistrationDetailPage from './pages/my-class/e-learning-registration-detail/[id]'
import LoggingIn from './modules/Login/loggingIn'
import WaitingForLoginPage from './pages/login/waiting-for-login'
import GradesPage from './pages/my-class/grades/[id]'
import ELearningGradesPage from './pages/my-class/e-learning-grades/[id]'
import LearningPathGradesPage from './pages/my-class/learning-path-grades/[id]'

// profile
import MyProfile from './pages/my-profile'
import ToDoList from './pages/my-profile/[type]'

// E-Learning
import ELearningCoursePage from './pages/e-learning/Course'
import ELearningCourseDetailPage from './pages/e-learning/detail/[id]'
import ELearningCourseEnrollPage from './pages/e-learning/enroll/[id]'
import ELearningCourseVerificationPage from './pages/e-learning/verification/[id]'
import ELearningCourseLearningPage from './pages/e-learning/learning/[id]'
import ElearningLearningPathDetailPage from './pages/e-learning/learning-path/[id]'
import ElearningLearningPathPage from './pages/e-learning/learning-path'
import ElearningCollectionDetailPage from './pages/e-learning/collection/[id]'
import ElearningCollectionListPage from './pages/e-learning/collection'

import EExaminationPage from './pages/e-examination'

import ClassLearnerPage from './pages/class-learner'
import CourseBatchDetailPage from './pages/class-learner/detail'

import AnnounceExaminerPage from './pages/announce-examiner'
import AnnounceExaminerDetailPage from './pages/announce-examiner/detail'
import CatalogPage from './pages/catalog'
import { LivenessQuickStartReact } from './pages/face-liveness'

import EContent from './pages/e-content'
import EContentList from './pages/e-content/list'
import EContentFactPage from './pages/e-content/kb/[id]'
import EContentArticlePage from './pages/e-content/content/[id]'
import EContentSearchPage from './pages/e-content/search'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

export const routeList = [
  { path: '/logging-in', component: LoggingIn },
  { path: '/login', component: LoginPage },
  { path: '/waiting-for-login', component: WaitingForLoginPage },
  { path: '/forget-password', component: ForgetPasswordPage },
  { path: '/', component: HomePage },
  { path: '/course', component: CoursePage },
  { path: '/course/:id', component: CourseDetailPage },
  { path: '/course', component: CoursePage },
  { path: '/course/:id', component: CourseDetailPage },
  {
    path: '/course/:uuid/class/:trainingPlanUuid',
    component: ClassDetailPage,
  },
  { path: '/my-class-old', component: MyClassOldPage },
  { path: '/my-class', component: MyClassPage },
  {
    path: '/my-class/registration-detail/:id',
    component: RegistrationDetailPage,
  },
  {
    path: '/my-class/registration-detail/grades/:id',
    component: GradesPage,
  },
  {
    path: '/my-class/e-learning-registration-detail/:id',
    component: ELearningRegistrationDetailPage,
  },
  {
    path: '/my-class/e-learning-registration-detail/grades/:id',
    component: ELearningGradesPage,
  },
  {
    path: '/my-learning-path/grades/:id',
    component: LearningPathGradesPage,
  },
  { path: '/location', component: LocationPage },
  { path: '/location', component: LocationPage },
  // E-Evaluation
  { path: '/e-evaluation', component: EEvaluationIndex },
  { path: '/e-evaluation', component: EEvaluationIndex },
  {
    path: '/e-evaluation/:codeId/:version/:id',
    component: EEvaluation,
  },
  {
    path: '/e-evaluation/evaluate/:codeId/:version/:id',
    component: EEvaluationEvaluate,
  },
  { path: '/e-evaluation/:id', component: EEvaluation },
  { path: '/e-evaluation/:id', component: EEvaluation },
  {
    path: '/e-evaluation/evaluate/:id',
    component: EEvaluationEvaluate,
  },
  { path: '/e-testing', component: ETestingIndex },
  { path: '/e-testing', component: ETestingIndex },
  {
    path: '/e-testing/:codeId/:version/:id',
    component: ETesting,
  },
  {
    path: '/e-testing/on-test/:codeId/:version/:id',
    component: ETestingTesting,
  },
  { path: '/e-testing/:id', component: ETesting },
  { path: '/e-testing/on-test/:id', component: ETestingTesting },
  { path: '/e-testing/on-review/:id', component: ETestingReview },
  { path: '/e-testing/:id', component: ETesting },
  { path: '/e-testing/on-test/:id', component: ETestingTesting },
  { path: '/e-testing/on-review/:id', component: ETestingReview },
  // document
  { path: '/upload-document/:id', component: DocumentPage },
  { path: '/consent/:uuid', component: AdditionalInfoConsentPage },
  { path: '/upload-document/:id', component: DocumentPage },
  { path: '/consent/:uuid', component: AdditionalInfoConsentPage },
  {
    path: '/consent/upload-document/:uuid',
    component: AdditionalInfoConsentAndDocsPage,
  },
  // check in
  { path: '/check-in/:id', component: CheckIn },
  { path: '/check-in/:id', component: CheckIn },
  { path: '/liveness', component: LivenessQuickStartReact },

  // prospect
  {
    path: '/manage-prospect',
    component: ManageProspectPage,
    objRole: { userType: ['STAFF', 'AGENT'], role: 'Manager' },
  },
  {
    path: '/manage-prospect/enrollment',
    component: EnrollmentPage,
    objRole: { userType: ['STAFF', 'AGENT'], role: 'Manager' },
  },
  {
    path: '/manage-prospect/create',
    component: ManageProspectForm,
    objRole: { userType: [''], role: '' },
  },
  {
    path: '/manage-prospect/:id',
    component: ManageProspectFormEdit,
    objRole: { userType: [''], role: '' },
  },
  {
    path: '/manage-prospect/view/:id',
    component: ManageProspectFormView,
    objRole: { userType: ['STAFF', 'AGENT'], role: 'Manager' },
  },
  // profile
  { path: '/my-profile', component: MyProfile },
  { path: '/my-profile', component: MyProfile },
  // to-do-list
  { path: '/my-profile/:type', component: ToDoList },
  // E-Learning
  { path: '/e-learning', component: ELearningCoursePage },
  { path: '/e-learning/course', component: ELearningCoursePage },
  { path: '/e-learning/course/:id', component: ELearningCourseDetailPage },
  {
    path: '/e-learning/course/enroll/:id',
    component: ELearningCourseEnrollPage,
  },
  {
    path: '/e-learning/course/enroll/verification/:id',
    component: ELearningCourseVerificationPage,
  },
  {
    path: '/e-learning/course/learning/:id',
    component: ELearningCourseLearningPage,
  },
  {
    path: '/e-learning/learning-path',
    component: ElearningLearningPathPage,
  },
  {
    path: '/e-learning/learning-path/:id',
    component: ElearningLearningPathDetailPage,
  },
  {
    path: '/e-learning/collection/:id',
    component: ElearningCollectionDetailPage,
  },
  {
    path: '/e-learning/collection/',
    component: ElearningCollectionListPage,
  },
  // E-Examination
  { path: '/e-examination/', component: EExaminationPage },
  {
    path: '/learners',
    component: ClassLearnerPage,
  },
  {
    path: '/learners/:id',
    component: ClassLearnerPage,
  },
  {
    path: '/learners/detail/:id',
    component: CourseBatchDetailPage,
  },
  {
    path: '/announce-examiner',
    component: AnnounceExaminerPage,
  },
  {
    path: '/announce-examiner/detail/:id',
    component: AnnounceExaminerDetailPage,
  },
  // Catalog
  { path: '/catalog', component: CatalogPage },

  // E-content
  { path: '/e-content/', component: EContent },
  { path: '/e-content/list', component: EContentList },
  { path: '/e-content/article/:id', component: EContentArticlePage },
  { path: '/e-content/fact/:id', component: EContentFactPage },
  { path: '/e-content/search', component: EContentSearchPage },
]

const withLayout = (WL, isRole, verifiedByiRecruit) => {
  const dispatch = useDispatch()
  const Comp = (props) => {
    if (isRole || !verifiedByiRecruit) {
      dispatch(
        openDialog({
          isCloseDialog: false,
          iconType: 'error',
          title: 'ขออภัยเนื่องจากคุณไม่มีสิทธิ์เข้าถึง',
          message: [''],
          buttonRight: {
            label: 'ตกลง',
            handleClick: () => {
              if (!verifiedByiRecruit) {
                window.location.href = '/dms'
              } else {
                window.location.href = '/'
              }
            },
          },
        })
      )
      return (
        <Layout layout={WL.templateLayout}>
          <Template>
            <Box sx={{ height: '50vh' }} />
          </Template>
        </Layout>
      )
    }
    return (
      <Layout layout={WL.templateLayout}>
        <WL {...props} />
      </Layout>
    )
  }
  Comp.displayName = 'withLayout'
  return Comp
}

export const checkRole = (user, objRole) => {
  const { uuid } = useParams()
  if (_.isNull(objRole)) return false
  const localUserType = _.get(user, 'userType', 'STAFF')
  const localRole = _.get(user, 'agentRole', '')
  const { userType, role } = objRole
  let isRole =
    localUserType === 'STAFF' || localUserType === 'PROSPECT'
      ? true
      : _.isArray(role)
      ? role.includes(localRole)
      : _.isEqual(role, localRole)
  let isUserType =
    localUserType === 'PROSPECT' && user.uuid === uuid
      ? true
      : _.isArray(userType)
      ? userType.includes(localUserType)
      : _.isEqual(userType, localUserType)

  return !(isUserType && isRole)
}

const isFromDMS = (user) => _.get(user, 'prospectProfile.isDMS', false)
const isVerifiedByiRecruit = (user) =>
  _.get(user, 'isVerifiedByiRecruit', false)

const RoutePage = ({ user }) => {
  return (
    <Switch>
      {routeList.map((item) => {
        const WL = item.component?.templateLayout
        let verifiedByiRecruit = true
        if (WL !== 'login' && isFromDMS(user)) {
          verifiedByiRecruit = isVerifiedByiRecruit(user)
        }
        const isRole = checkRole(user, _.get(item, 'objRole', null))
        return (
          <Route
            key={item.path}
            exact
            path={item.path}
            component={withLayout(item.component, isRole, verifiedByiRecruit)}
          />
        )
      })}
    </Switch>
  )
}
export default RoutePage
