import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { io } from 'socket.io-client'
import { resetState } from 'src/redux/slices/eLearning/learning'
import { COURSE_TYPE, MODULE_TYPE } from 'src/constants/eLearning'
import { getQueryParams } from 'src/utils/lib'
import FloatingButtons from './components/FloatingButtons'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import CameraContent from './CameraDialog'
import ClickDialog from './ClickDialog'
import LearningAlertDialog from './AlertDialog'
import { initialDialog } from './AlertDialog/model'
import { StyledContainer } from './Styled'
import { handleInitLearning, handleSocketResponse } from './events'
import updateTimeSpentlog from './handler/updateTimeSpentLog'
import clearTimeSpentLogInterval from './handler/clearTimeSpentLogInterval'
import initialTimeSpentLog from './handler/initialTimeSpentLog'
import { useWindowFocus } from './hooks/useWindowFocus'
import handleWindowFocus from './handler/handleWindowFocus'

const { __env__: env } = window

const socket = io.connect(env.REACT_APP_SOCKET_URL, {
  autoConnect: false,
  transports: ['websocket', 'polling'],
  auth: {
    Authorization: localStorage.getItem('token'),
  },
})

const Learning = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const batchUuid = getQueryParams('batch')
  const {
    course,
    INTERVAL_TIME,
    MAXIMUM_RETRY,
    timeSpentLogUuid,
    selectedLesson,
  } = useSelector(
    (state) => ({
      course: state.eLearningLearning.course,
      INTERVAL_TIME: state.eLearningLearning.INTERVAL_TIME,
      MAXIMUM_RETRY: state.eLearningLearning.MAXIMUM_RETRY,
      timeSpentLogUuid: state.eLearningLearning.timeSpentLogUuid,
      selectedLesson: state.eLearningLearning.selectedLesson,
    }),
    shallowEqual
  )
  const courseType = _.get(course, 'courseType', '')
  const isOic = courseType === COURSE_TYPE.OIC
  const [socketId, setSocketId] = useState('')
  const uuidProps = { uuid, batchUuid }
  const [alertDialog, setAlertDialog] = useState({ ...initialDialog })
  const isTabFocus = useWindowFocus()

  /* ------ socket handling ------- */
  useEffect(() => {
    socket.connect()

    return () => {
      dispatch(resetState())
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    const onConnect = () => {
      console.log('socket connected', socket.connected)
      if (socket.connected) {
        setSocketId(socket.id)
        socket.on(socket.id, (res) => {
          dispatch(handleSocketResponse(res, socket, uuidProps))
        })
      }
    }

    const onDisconnect = () => socket.disconnect()

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [])

  useEffect(() => {
    dispatch(handleInitLearning(socketId, uuidProps))
  }, [socketId])
  /* ------ socket handling ------- */

  useEffect(() => {
    if (env.ENABLE_OIC_EL !== 'true') return

    // interval update log time spent and log learner
    if (
      !_.isEmpty(MAXIMUM_RETRY) &&
      !_.isEmpty(INTERVAL_TIME) &&
      !_.isEmpty(timeSpentLogUuid) &&
      [MODULE_TYPE.EMBED_CONTENT, MODULE_TYPE.SCORM].includes(
        selectedLesson.moduleType
      )
    ) {
      const interval = setInterval(
        () => dispatch(updateTimeSpentlog()),
        INTERVAL_TIME * 1000
      )
      localStorage.setItem('updateIntervalId', interval)
    } else {
      clearTimeSpentLogInterval('updateIntervalId')
    }
    return () => {
      clearTimeSpentLogInterval()
    }
  }, [MAXIMUM_RETRY, INTERVAL_TIME, timeSpentLogUuid])

  useEffect(() => {
    /* create new time spent log
       on first fetch and switch module */
    dispatch(initialTimeSpentLog())
  }, [selectedLesson.uuid])

  useEffect(() => {
    // handle focus and unfocus tab
    dispatch(handleWindowFocus(isTabFocus))
  }, [isTabFocus])

  if (_.isEmpty(course)) return <></>

  return (
    <Box sx={{ position: 'relative' }}>
      {isOic && (
        <>
          <CameraContent />
          <ClickDialog />
        </>
      )}

      <StyledContainer>
        <FloatingButtons />

        <LeftPanel />

        <RightPanel setAlertDialog={setAlertDialog} />
      </StyledContainer>

      <LearningAlertDialog
        alertDialog={alertDialog}
        setAlertDialog={setAlertDialog}
      />
    </Box>
  )
}

export default Learning
