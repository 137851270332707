const clearTimeSpentLogInterval = (key = '') => {
  if (key) {
    const intervalId = localStorage.getItem(key)
    if (intervalId) {
      clearInterval(intervalId)
      localStorage.setItem(key, '')
    }
  } else {
    const updateIntervalId = localStorage.getItem('updateIntervalId')
    if (updateIntervalId) {
      clearInterval(updateIntervalId)
      localStorage.setItem('updateIntervalId', '')
    }

    const retryIntervalId = localStorage.getItem('retryIntervalId')
    if (retryIntervalId) {
      clearInterval(retryIntervalId)
      localStorage.setItem('retryIntervalId', '')
    }
  }
}

export default clearTimeSpentLogInterval
