import { setTimeSpentLogUuid } from 'src/redux/slices/eLearning/learning'
import clearTimeSpentLogInterval from './clearTimeSpentLogInterval'
import initialTimeSpentLog from './initialTimeSpentLog'

const handleWindowFocus = (isTabFocus) => (dispatch) => {
  if (!isTabFocus) {
    // unfocus ลบ interval update และ เก็บเวลาที่เรียนไปแล้วแต่ยังไม่ได้อัปเดต
    clearTimeSpentLogInterval('updateIntervalId')

    const latestStampLog = localStorage.getItem('latestStampLog')
    const timeStored = localStorage.getItem('timeStored')
    const newLatestStampLog = Date.now()
    const newTimeStored = Math.floor(
      (newLatestStampLog - latestStampLog) / 1000
    )

    localStorage.setItem('timeStored', +timeStored + +newTimeStored)
    dispatch(setTimeSpentLogUuid(''))
  } else {
    // refocus insert new log
    dispatch(initialTimeSpentLog(true))
  }
}

export default handleWindowFocus
